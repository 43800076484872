<template>
  <Layout :tituloPagina="`Network | Dispositivos para hogar | Tipos`">
    <div class="row">
      <div class="col-lg-5">
        <cmp-secciones seccion="tipos"></cmp-secciones>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="text-right">
          <router-link :to="{ name: 'nuevoTipoDhs' }" class="btn btn-success">
            <i class="mdi mdi-plus-thick"></i>
            Nuevo
          </router-link>
        </div>

        <br />

        <div class="card">
          <div class="card-body" style="min-height: 250px;">
            <div class="table-responsive">
              <table id="tblTipos" class="table table-hover table-nowrap">
                <thead>
                  <tr>
                    <th>Identificador</th>
                    <th style=" width: 180px">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="tipo in tipos" :key="tipo.id">
                    <td>{{ tipo.nombre }}</td>
                    <td>
                      <router-link
                        :to="{
                          name: 'edicionTipoDhs',
                          params: { id: tipo.id }
                        }"
                        class="btn btn-warning btn-sm"
                      >
                        <i class="mdi mdi-content-save-edit-outline"></i>
                        Editar
                      </router-link>

                      <button
                        class="btn btn-danger btn-sm"
                        @click="preguntaEliminar(tipo)"
                      >
                        <i class="mdi mdi-trash-can-outline"></i>
                        Eliminar
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from '@/views/layouts/main'
import CmpSecciones from '../CmpSecciones.vue'
import TipoDhsSrv from '@/services/TipoDhsSrv.js'

import Swal from 'sweetalert2'
export default {
  name: 'ListadoTiposDhs',
  components: { Layout, CmpSecciones },
  data() {
    return {
      tipos: []
    }
  },

  created: function() {
    var self = this

    // Cargas iniciales
    self.refrescarTipos()
  },

  methods: {
    preguntaEliminar: function(tipo) {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });

      swal.fire({
        title: "Eliminación de tipo",
        html: `¿Desea eliminar el tipo <strong>${tipo.nombre}</strong>?`,
        icon: "warning",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          TipoDhsSrv.eliminar(tipo.id).then(response => {
            swal.fire("Eliminado!", "Se eliminó correctamente", "success");
            self.refrescarTipos()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = 'No se pudo eliminar el tipo'
            }
            iu.msg.error(mensaje)
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },
    refrescarTipos: function() {
      var self = this

      iu.spinner.mostrar('#tblTipos')

      TipoDhsSrv.tiposJSON().then(response => {
        self.tipos = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron refrescar los tipos de DHs'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally (() => {
        iu.spinner.ocultar('#tblTipos')
      })
    }
  },
  mounted: function() {
    iu.spinner.mostrar('#tblTipos')
  }
}
</script>

<style scoped>

</style>