import axios from 'axios'
import API from '@/API.js'

const apiClient = axios.create({
  baseURL: `${API}/dhs/tipos`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('argusblack.token')
  }
})

// Actualización del token de autorización
document.addEventListener('actualizar:token', function(e) {
  apiClient.defaults.headers['Authorization'] = e.detail
})

// Eliminación del token de autorización
document.addEventListener('eliminar:token', function() {
  apiClient.defaults.headers['Authorization'] = null
})

export default {
  actualizar(tipo) {
    return apiClient.post(`${tipo.id}`, tipo)
  },

  eliminar(idTipo) {
    return apiClient.delete(`${idTipo}`)
  },

  guardar(tipo) {
    return apiClient.post('', tipo)
  },

  tipoJSON(idTipo) {
    return apiClient.get(`${idTipo}.json`)
  },

  tiposJSON(params) {
    params = params || {}
    return apiClient.get(`${API}/dhs/tipos.json`, { params: params })
  }
}
